import { navigate } from "gatsby"
import { useEffect, useState } from "react"
import { useLocation } from "react-use"
import slugify from "slugify"
import { modulo, select } from "../../utils/helpers"

const useActiveProjectLogic = projects => {
  const { hash } = useLocation()

  const [activeProject, setActiveProject] = useState(null)

  const handlePushUrl = project => {
    const newPath = "/portfolio/#" + slugify(project.projectTitle)
    console.log("navgating to", newPath)

    navigate(newPath, {
      state: {
        disableScrollUpdate: true,
      },
    })
  }

  const viewProject = project => {
    handlePushUrl(project)
  }

  const nextActiveProject = () => {
    const indexOfNextProject = modulo(
      projects.indexOf(activeProject) + 1,
      projects.length
    )
    handlePushUrl(projects[indexOfNextProject])
  }
  const prevActiveProject = () => {
    const indexOfPrevProject = modulo(
      projects.indexOf(activeProject) - 1,
      projects.length
    )
    handlePushUrl(projects[indexOfPrevProject])
  }

  useEffect(() => {
    const setActiveProjectOnHashChange = () => {
      const hashLocation = hash.split("#")[1]
      const hashProject = projects.find(
        project => slugify(project.projectTitle) === hashLocation
      )
      setActiveProject(hashProject)
    }
    setActiveProjectOnHashChange()
  }, [hash]) // eslint-disable-line

  useEffect(() => {
    const scrollToProjectsOnLoad = () => {
      const hashLocation = hash.split("#")[1]
      const hashProject = projects.find(
        project => slugify(project.projectTitle) === hashLocation
      )
      if (hashProject) window.scrollTo(0, select("#projects").offsetTop)
    }
    scrollToProjectsOnLoad()
  }, []) // eslint-disable-line

  return {
    activeProject,
    setActiveProject,
    viewProject,
    nextActiveProject,
    prevActiveProject,
  }
}
export default useActiveProjectLogic
