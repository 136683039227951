import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import Img from "gatsby-image"
import { useLocation } from "@reach/router"
import Btn from "../Btn"
import { useEffect } from "react"
import { returnContentfulMediaType, select } from "../../utils/helpers"

import { AiFillFacebook, AiFillInstagram } from "react-icons/ai"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"

import "./ProjectModal.scss"
import { navigate } from "gatsby"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const SlideMedia = ({ mediaItem }) => {
  const mediaType = returnContentfulMediaType(mediaItem)

  console.log({ mediaType, mediaItem })

  if (mediaType === "image") {
    return (
      <Img
        //   fluid={mediaItem.localFile.childImageSharp.fluid}
        fluid={mediaItem.fluid}
        imgStyle={{ objectFit: "cover" }}
      />
    )
  }
  if (mediaType === "video") {
    const videoUrl = mediaItem.localFile.publicURL
    return <video src={videoUrl} autoPlay muted loop></video>
  }
  return <div>Media missing</div>
}

const HomeProjectModal = ({
  projects,
  activeProject,
  nextActiveProject,
  prevActiveProject,
}) => {
  const projectIndex = projects.indexOf(activeProject)
  const isFirstProject = projectIndex === 0
  const isLastProject = projectIndex === projects.length - 1

  const [controlledSwiper, setControlledSwiper] = useState(null)

  const location = useLocation()
  console.log(location)

  useEffect(() => {
    if (!controlledSwiper) return
    controlledSwiper.slideTo(0)
  }, [controlledSwiper])

  useEffect(() => {
    if (activeProject) {
      select("html").style.overflow = "hidden"
    } else {
      select("html").style.overflow = ""
    }
    return () => {
      select("html").style.overflow = ""
    }
  }, [activeProject])

  console.log(activeProject)

  return (
    <>
      <AnimatePresence>
        {activeProject && (
          <motion.div
            className="project-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              pointerEvents: "none",
            }}
          >
            <motion.div
              className="project-modal-overlay__background"
              whileHover={{ opacity: 0.75 }}
              onClick={() => {
                const newPath = "/portfolio/"
                navigate(newPath, {
                  state: {
                    disableScrollUpdate: true,
                  },
                })
              }}
            ></motion.div>

            <AnimatePresence exitBeforeEnter>
              {activeProject && (
                <motion.div
                  className="project-modal shadow-xl"
                  initial={{ opacity: 0, y: 40 }}
                  animate={{ opacity: activeProject ? 1 : 0, y: 0 }}
                  exit={{ opacity: 0, transition: { delay: 0, duration: 0.3 } }}
                  transition={{ delay: 0.3 }}
                  key={activeProject.projectTitle}
                >
                  <div className="project-modal__media">
                    {/* <Img fluid={projectItemImageFluid} /> */}
                    <Swiper
                      spaceBetween={50}
                      slidesPerView={1}
                      navigation
                      pagination={{ clickable: true }}
                      scrollbar={{ draggable: true }}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={setControlledSwiper}
                    >
                      {activeProject.projectMedia.map((mediaItem, index) => (
                        <SwiperSlide key={index}>
                          <SlideMedia mediaItem={mediaItem} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="project-modal__text-container">
                    <div className="project-modal__text-content">
                      <div className="top-section">
                        <h4>{activeProject.projectTitle}</h4>
                        {/* <h5>
                          <span>{projectIndex + 1}</span>/{projects.length}
                        </h5> */}
                      </div>
                      <p>{activeProject.projectDesc?.projectDesc}</p>
                    </div>
                    <div className="project-modal__btns-container">
                      <div className="project-modal__socials">
                        {/* <a href="">
                          <AiFillInstagram />
                        </a> */}
                        {/* <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                        >
                          <AiFillFacebook />
                        </a> */}
                      </div>

                      {!isFirstProject && (
                        <Btn
                          color="dark"
                          size="sm"
                          className="project-modal__prev-btn"
                          onClick={() => prevActiveProject()}
                        >
                          Prev project
                        </Btn>
                      )}
                      <Btn
                        color="dark"
                        size="sm"
                        className="project-modal__next-btn"
                        onClick={() => nextActiveProject()}
                      >
                        {isLastProject ? "First project" : "Next project"}
                      </Btn>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default HomeProjectModal
