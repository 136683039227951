import React, { useEffect } from "react"
import { motion } from "framer-motion"

import { IoIosArrowDown } from "react-icons/io"
import Image from "../../assets/images/images"

import "./PortfolioHero.scss"
import HeroMediaComponent from "../HeroMediaComponent"
import { select } from "../../utils/helpers"
import gsap from "gsap"

const PortfolioHero = ({ pageTitle, heroImage }) => {
  useEffect(() => {
    const mainHeadline = select(".portfolio-hero__text-content h1 span")
    const heroParagraph = select(".portfolio-hero__text-content p")
    const heroMedia = select(".home-hero__image, .home-hero__video")

    const tl = gsap.timeline()
    tl.from(mainHeadline, {
      y: 100,
      opacity: 0,
      duration: 1,
      ease: "power4.out",
      delay: 0.75,
    })
      //   .from(
      //     heroParagraph,
      //     {
      //       opacity: 0,
      //       duration: 1,
      //       ease: "power4.out",
      //     },
      //     "<0.75"
      //   )
      .from(
        heroMedia,
        {
          opacity: 0,
          duration: 2,
          ease: "power2.iOout",
        },
        "<0.75"
      )

    // tl.to("body", { opacity: 0.5 })
  }, [])

  return (
    <section className="portfolio-hero ">
      {/* <Image className="portfolio-hero__image" src="virtualReality" /> */}
      <HeroMediaComponent media={heroImage} />
      <div className="portfolio-hero__text-content">
        <h1 className="overflow-hidden leading-none">
          <span className="block">{pageTitle}</span>
        </h1>
        <motion.div
          animate={{
            y: [0, 0, 12, 0],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.7, 0.8, 1],
            loop: Infinity,
            repeatDelay: 1,
          }}
        >
          <IoIosArrowDown className="scroll-down-chevron" stroke="0" />
        </motion.div>
      </div>
    </section>
  )
}

export default PortfolioHero
