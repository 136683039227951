import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SEO from "../seo"

import PortfolioHero from "./PortfolioHero"
import PortfolioProjects from "./PortfolioProjects"

const PortfolioPage = () => {
  const { pageTitle, heroImage, featuredProjects } = usePortfolioPageQuery()
  return (
    <div>
      <SEO title="Portfolio" description="View Max VR's recent projects." />
      <PortfolioHero pageTitle={pageTitle} heroImage={heroImage} />
      <PortfolioProjects featuredProjects={featuredProjects} />
    </div>
  )
}

export default PortfolioPage

const usePortfolioPageQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulPortfolioPage {
          pageTitle
          heroImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            file {
              contentType
              url
            }

            localFile {
              publicURL
            }
          }
          featuredProjects {
            projectTitle
            projectDesc {
              projectDesc
            }
            projectMedia {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 960, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              file {
                contentType
                url
              }
              fluid(maxWidth: 800, quality: 75) {
                ...GatsbyContentfulFluid_withWebp
              }
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `
  )
  return data.contentfulPortfolioPage
}
