import { Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"

import { motion } from "framer-motion"

import "./PortfolioProjects.scss"
import { useState } from "react"
import slugify from "slugify"
import Btn from "../Btn"

import ProjectModal from "./ProjectModal"
import useProjectModalLogic from "./useProjectModalLogic"
import useProjectsQuery from "./useProjectsQuery"
import PageTransitionLink from "../PageTransitionLink"

const PortfolioProjectItem = ({ project, viewProject }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { projectTitle } = project
  const projectItemImageFluid =
    project.projectMedia[0].localFile.childImageSharp.fluid
  const projectItemContentful = project.projectMedia[0].fluid

  return (
    <motion.div
      className="portfolio-project-item"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (!isHovered) setIsHovered(true)
      }}
    >
      <motion.div
        className="portfolio-project-item__image shadow-md"
        transition={{ duration: 0.3 }}
        animate={{ opacity: isHovered ? 0.3 : 1 }}
      >
        <Img fluid={projectItemContentful} />
      </motion.div>

      <div className="portfolio-project-item__text">
        {/* <motion.p
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          animate={{ opacity: isHovered ? 1 : 0, y: isHovered ? 0 : 20 }}
        >
          {projectShortDesc.projectShortDesc}
        </motion.p> */}
        <motion.h5
          transition={{ duration: 0.3 }}
          //   animate={{ opacity: isHovered ? 1 : 0.75, scale }}
          initial={{ scale: 0.6, opacity: 0.75 }}
          animate={{
            scale: isHovered ? 0.8 : 0.6,
            opacity: isHovered ? 1 : 0.75,
          }}
          className="origin-bottom-left"
          onClick={() => viewProject(project)}
        >
          {projectTitle}
        </motion.h5>
      </div>

      <Btn
        color="white"
        size="sm"
        initial={{ opacity: 0 }}
        transition={{ duration: 0.3, delay: isHovered ? 0.15 : 0 }}
        animate={{
          opacity: isHovered ? 1 : 0,
          y: isHovered ? 0 : 20,
          pointerEvents: isHovered ? "" : "none",
        }}
        onClick={() => viewProject(project)}
      >
        <PageTransitionLink
          to={"/portfolio/" + "#" + slugify(project.projectTitle)}
        >
          View project
        </PageTransitionLink>
      </Btn>
    </motion.div>
  )
}

const PortfolioProjects = ({ featuredProjects }) => {
  const {
    activeProject,
    setActiveProject,
    nextActiveProject,
    prevActiveProject,
    viewProject,
  } = useProjectModalLogic(featuredProjects)

  return (
    <>
      <section className="portfolio-projects" id="projects">
        <div className="container">
          {/* <h2 className="portfolio-projects__title">Our Projects.</h2> */}
          {/* <p className="portfolio-projects__description"></p> */}
          <div className="portfolio-projects__item-container">
            {featuredProjects.map(project => (
              <PortfolioProjectItem
                isActiveProject={activeProject === project}
                project={project}
                viewProject={viewProject}
                key={project.projectTitle}
              />
            ))}
          </div>
        </div>
      </section>
      <ProjectModal
        projects={featuredProjects}
        activeProject={activeProject}
        nextActiveProject={nextActiveProject}
        prevActiveProject={prevActiveProject}
      />
    </>
  )
}

export default PortfolioProjects
